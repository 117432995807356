import $ from 'jquery';
import Mn from 'backbone.marionette';
import _ from 'underscore';

var body = $('html, body'),
    Helper =  Mn.Object.extend({
        link: function (href) {
            var a = document.createElement('a');
            a.href = href;
            return a.toString();
        },
        assets_difference: function (elements_a, elements_b, key) {
            var self = this,
                src1 = _.pluck(elements_a, key),
                src2 = _.pluck(elements_b, key),
                diff = _.difference(src1, src2),
                difference = _.filter(elements_a, function(obj) { 
                    return diff.indexOf(obj[key]) >= 0; 
                });

            return difference;
        },
        get_components: function(html){
            var childComponents = $(html).find('[data-component]');
            var childPage = $(html).find('[data-view]');

            childComponents = childComponents.filter(function(){
                return $(this).closest(childPage).length === 0 && $(this).closest(childComponents.not(this)).length === 0;
            });

            return childComponents.length > 0 ? childComponents : false;
        },
        html_difference: function (elements_a, elements_b, includeChilds) {
            var clear = /(\s+)?(\r\n|\n|\r)?(\s+)?/gim;
            var clone_a = $(elements_a).clone();
            var clone_b = $(elements_b).clone();

            var components_a = this.get_components(clone_a);
            var components_b = this.get_components(clone_b);

            if(components_a === false != components_b === false) {
                return true;
            }

            if(!includeChilds){
                if(components_a){
                    components_a.remove();
                }

                if(components_b){
                    components_b.remove();
                }
            }

            var html_a = clone_a.html().replace(clear, "");
            var html_b = clone_b.html().replace(clear, "");

            return html_a != html_b;
        },
        content: function (content) {
            return content.trim() || '<div ><span class="empty"></span></div>';
        },
        scrollTo: function(target, speed, callback){
            var where = body;
            if(target === undefined || target === null || target === false){
                target = 0;
            } else if($.isNumeric(target)){
                //pass
            } else{
                target = $(target);

                if(target.length >= 1){
                    if (target.closest('.jquery-modal').length) {
                        where = target.closest('.jquery-modal')
                    }
                    target = target.offset().top;
                }else{
                    target = 0;
                }
            }

            where.stop();

            if(!$('body').hasClass('menu-scroll')){
                target = target;
            }

            where.animate({
                scrollTop: target - $('.header__wrapper').height() || 46
            }, speed || 500, 'swing', function() {
                if(callback) callback(this);
            });
        },
        keepTrying: function(fncode, done, limit, time){
            var self = this, status;

            try{
                status = true;

                fncode();
            }catch(e){
                status = false;

                if(limit > 0){
                    setTimeout(function(){
                        self.keepTrying.apply(self, [fncode, done, --limit, time])
                    }, time || 500);

                    console.log(e);
                    return;
                }
            }

            done && done(status);
        },
        getParameterByName: function(name, url) {
            url = url || window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        updateQueryStringParameter(uri, key, value) {
            var re = new RegExp("([?&])" + key.replace('[', '\\[').replace(']', '\\]') + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            }
            else {
                return uri + separator + key + "=" + value;
            }
        }
    });

export default new Helper();