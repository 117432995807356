import $ from 'jquery';
import Mn from 'backbone.marionette';
import scope from '../scope';
import helpers from '../libs/helpers';
import { has } from 'underscore';

var Behavior = Mn.Behavior.extend({
    ui: {
        field: '[data-updateparam]'
    },
    events: {
        'change @ui.field': 'doUpdate'
    },
    doUpdate: function(e){
        e.preventDefault();

        var url = window.location.href;
        var hash = window.location.hash;
        var target = $(e.currentTarget);
        var options = {
            target: target,
            name: target.attr('name'),
            value: target.val(),
            hash: hash,
        }

        options.params = {};
        options.params[options.name] = options.value;

        
        target.trigger('updateparam', [options]);

        $.each(options.params, function(key, val){
            console.log(arguments);
            url = helpers.updateQueryStringParameter(url, key, val);
        });

        setTimeout(function(){
            scope.app.navigate(url.replace('pushstate=true',''), false, options.hash);
        }, 150);
    },
});

export default Behavior;